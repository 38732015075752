<template>
  <food-plan
    ref="planComponent"
    :parent="parent"
    :client="client"
    :plan="foodPlan"
    :baseUsed="baseUsed"
    :headerFixed="true"
    :hasBack="true"
  />
</template>

<script>
  import Utils from '@/services/Utils'

  export default {
    components: {
      FoodPlan: () => import('@/views/dashboard/components/foodPlan/FoodPlan'),
    },
    data () {
      const data = Utils.getStorage('foodPlan')
      const parent = data ? data.parent : null
      const client = data ? data.client : {}
      const foodPlan = data ? data.data : {}
      const baseUsed = data ? data.baseUsed : false
      return {
        foodPlan: foodPlan,
        parent: parent,
        client: client,
        baseUsed: baseUsed,
      }
    },
    beforeRouteLeave: function (to, from, next) {
      if (this.$refs.planComponent && this.$refs.planComponent.destroyValidation) {
        this.$refs.planComponent.destroyValidation(next)
      }
    },
  }
</script>
